import team1 from"../../assets/team1.jpg"
import team2 from"../../assets/team2.jpg"
import team3 from"../../assets/team3.jpg"

const DataSlider1 = [
    {
        id:1,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team1
    },
    {
        id:2,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team2
    },
    {
        id:3,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team3
    },
    {
        id:4,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team1
    },
    {
        id:5,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team2
    },
    {
        id:6,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team3
    },

]
export default DataSlider1