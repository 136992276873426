import Logo from '../../assets/logo.svg'

const DataPortFolio = [
    {
        id:1,
        img:Logo
    },
    {
        id:2,
        img:Logo
    },
    {
        id:3,
        img:Logo
    },
    {
        id:4,
        img:Logo
    },
    {
        id:5,
        img:Logo
    },
    {
        id:6,
        img:Logo
    },
    {
        id:7,
        img:Logo
    },
    {
        id:8,
        img:Logo
    },
    {
        id:9,
        img:Logo
    },
    {
        id:10,
        img:Logo
    },
    {
        id:11,
        img:Logo
    },
    {
        id:12,
        img:Logo
    },
    {
        id:13,
        img:Logo
    },
    {
        id:14,
        img:Logo
    },
    {
        id:15,
        img:Logo
    },
    {
        id:16,
        img:Logo
    },
]
export default DataPortFolio