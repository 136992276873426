import team4 from"../../assets/team4.jpg"
import team5 from"../../assets/team5.jpg"
import team6 from"../../assets/team6.jpg"

const DataSlider2 = [
    {
        id:1,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team4
    },
    {
        id:2,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team5
    },
    {
        id:3,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team6
    },
    {
        id:4,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team4
    },
    {
        id:5,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team5
    },
    {
        id:6,
        name:"ahmed ali",
        jop:"Ui/Ux/designer",
        img:team6
    },

]
export default DataSlider2