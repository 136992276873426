import styled from 'styled-components'
import HeaderProposal from '../Components/Proposals/HeaderProposal'
import Submission from '../Components/Proposals/Submission'



const Propisals = () => {

  return (
    <StyleAbout>
      <HeaderProposal/>
      <Submission />
    </StyleAbout>
  )
}
const StyleAbout= styled.div`
`
export default Propisals