import React from 'react'
import styled from 'styled-components'
import HeaderShips from '../Components/interships/HeaderShips'
import SectionShips from '../Components/interships/SectionShips'
const InterShips = () => {
  return (
    <StyleInterships>
        <HeaderShips />
        <SectionShips />
        
    </StyleInterships>
  )
}
const StyleInterships= styled.div`
`
export default InterShips