import React from 'react'
import HeaderPortfolio from '../Components/potofolio/HeaderPortfolio'
import SectionPortfolio from '../Components/potofolio/SectionPortfolio'

const Portfolio = () => {
  return (
    <div>
        <HeaderPortfolio/>
        <SectionPortfolio/>
    </div>
  )
}

export default Portfolio